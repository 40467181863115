import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCategory } from "../../redux/apiCalls";
import moment from "moment";
import styled from "styled-components";
import Table from "../../components/advancedTable";
import Select from "react-select";
import { publicRequest } from "../../requestMethods";

const statusOptions = [
    {
        label: "Inactive",
        value: "inactive",
    },
    {
        label: "Active",
        value: "active",
    },
];

const typeOptions = [
    {
        label: "Parts",
        value: "parts",
    },
    {
        label: "Sport Rim",
        value: "sportrim",
    },
    {
        label: "Material",
        value: "material",
    },
    {
        label: "Tyre",
        value: "tyre",
    },
    {
        label: "Lubricant",
        value: "lubricant",
    },
    {
        label: "Accessory",
        value: "accessory",
    },
    {
        label: "Labour",
        value: "labour",
    },
    {
        label: "Package",
        value: "package",
    },
];

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 80%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 40%;
    width: 100%;
    display: grid;
`;

export default function User() {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [inputs, setInputs] = useState({
        status: undefined,
        limit: 20,
        page: 1,
        name: undefined,
    });
    const [user, setUser] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [editModalState, setEditModalState] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            
                try {
                    const result = await publicRequest.get("/category", {
                        params: { inputs },
                    });

                    setUsersData(result ? result.data : []);
                    setIsLoaded(true);
                } catch (error) {
                    console.error("Error fetching users:", error);
                }
            }

        if (!isLoaded) {
        fetchData();
        }
        //eslint-disable-next-line
    }, [dispatch, inputs]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, user: e.target.value };
        });
    };


    const handleNameChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, name: e.target.value };
        });
        setName(e.target.value);
    };

    const handleInputStatus = (e) => {
        setInputs((prev) => {
            return { ...prev, page: 1, status: e.value };
        });
    };

    const handleTypeStatus = (e) => {
        setInputs((prev) => {
            return { ...prev, page: 1, type: e.value };
        });
    };

    const handleClick = async () => {
        const payload = {...user, status: inputs.status, type: inputs.type}
        updateCategory(user._id, payload);
    };

    const columns = [
        {
            accessor: "name",
            Header: "Name",
            width: 200,
            sortable: false,
        },
        {
            accessor: "type",
            Header: "Type",
            width: 200,
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "status",
            Header: "Status",
            width: 200,
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "createdAt",
            Header: "Created At",
            width: 200,
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            accessor: "action",
            Header: "Action",
            width: 200,
            sortable: false,
            Cell: (params) => {
                return (
                    <div
                        style={{
                            textDecoration: "none",
                            color: "black",
                        }}
                        to={"/warranty/" + params.row.original._id}
                        className="flex justify-start"
                    >
                        <button
                            onClick={() => {
                                setUser(params.row.original);
                                window.scrollTo(0, 0);
                                setEditModalState(true);
                            }}
                            className="productListEdit warrantyWidth"
                        >
                            Edit
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="productList">
                <div className="samerow">
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="name"
                            value={name}
                            type="string"
                            placeholder="Name"
                            onChange={handleNameChange}
                        />
                    </div>

                    <div className="sameRowItem marginRight">
                        <Select
                            options={statusOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleInputStatus}
                            placeholder="Status"
                        />
                    </div>
                </div>
                    <Table
                        data={usersData ? usersData : []}
                        columns={columns}
                        pageCount={1}
                        totalCount={1}
                        updatePagination={setInputs}
                        paginationState={inputs}
                        updateLoaded={setIsLoaded}
                        hidePagination={inputs.limit === 20 ? false : true}
                    />
            </div>

            {editModalState && (
                <OverlayContainer>
                    <ModalContainer
                        onClick={() => setEditModalState(false)}
                    ></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <ModalItemContainer>
                                <div className="updateTitle">
                                    <form className="addProductForm">
                                        <div className="addProductItem">
                                            <label>Name</label>
                                            <input
                                                name="name"
                                                type="text"
                                                placeholder={user.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="sameRowItem marginRight mb-2">
                        <Select
                            options={typeOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleTypeStatus}
                            placeholder="Type"
                        />
                    </div>
                                        <div className="addProductItem">
                                            <Select
                                                options={statusOptions.map(
                                                    (status, i) => {
                                                        return {
                                                            label: status.label,
                                                            value: status.value,
                                                        };
                                                    }
                                                )}
                                                onChange={handleInputStatus}
                                                placeholder="Status"
                                            />
                                        </div>
                                        <div
                                            onClick={handleClick}
                                            className="updateProductButton"
                                        >
                                            Update Brand
                                        </div>
                                    </form>
                                </div>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
        </>
    );
}
