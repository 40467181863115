import "./orderList.css";
import Table from "../../components/advancedTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatements } from "../../redux/apiCalls";
import moment from "moment";
import excel from "../../components/excel/excel";
import { useLocation } from "react-router";

export default function StatementList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);

    const [inputs, setInputs] = useState({
        createdBy: undefined,
        status: "completed",
        limit: 9999,
        page: 1,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        user: location
            ? location.state
                ? location.state.outlet
                : undefined
            : undefined,
    });

    const [ordersData, setOrdersData] = useState(false);
    const [serialNumber, setSerialNumber] = useState(undefined);

    const [turnover, setTurnOver] = useState(0);
    const [yearlyturnover, setYearlyturnover] = useState(0);
    const [cost, setCost] = useState(0);
    const [yearlycost, setYearlycost] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [yearlyexpenses, setYearlyexpenses] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getStatements(dispatch, inputs);

                setTurnOver(result.turnover.reduce(
                    (acc, item) => acc + item.monthly,
                    0
                ));

                setYearlyturnover(result.turnover.reduce(
                    (acc, item) => acc + item.yearly,
                    0
                ));

                setCost(result.goodssold.reduce(
                    (acc, item) => acc + item.monthly,
                    0
                ));

                setYearlycost(result.goodssold.reduce(
                    (acc, item) => acc + item.yearly,
                    0
                ));

                setExpenses(result.expenses.reduce(
                    (acc, item) => acc + item.monthly,
                    0
                ));

                setYearlyexpenses(result.expenses.reduce(
                    (acc, item) => acc + item.yearly,
                    0
                ));

                
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [dispatch, inputs]);

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const columns = [
        {
            accessor: "name",
            Header: "Name",
            sortable: false,
            width: 200,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "monthly",
            Header: "Month To Date",
            sortable: false,
            Cell: ({ value }) => {
                return (
                    <div className="initialPadding">
                        RM {formatter.format(value)}
                    </div>
                );
            },
        },
        {
            accessor: "yearly",
            Header: "Year To Date",
            sortable: false,
            Cell: ({ value }) => {
                return (
                    <div className="initialPadding">
                        RM {formatter.format(value)}
                    </div>
                );
            },
        },
    ];

    return (
        <>
        <div className="productList">
            <div className="flex justify-start">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="dateFrom"
                        value={inputs.dateFrom}
                        type="date"
                        onChange={(e) => handleDateFrom(e)}
                    />
                </div>
                <div className="addProductItem ">
                    <input
                        className="inputFilter"
                        name="dateTo"
                        value={inputs.dateTo}
                        type="date"
                        onChange={(e) => handleDateTo(e)}
                    />
                </div>
            </div>

            <div className="block">
                {superadmin && (
                    <>
                        <button
                            onClick={() => {
                                let payload = [];
                                ordersData.payload.forEach(
                                    (order, i) => {
                                        let productCodes =
                                            order.products
                                                .map(
                                                    (product) =>
                                                        `${product.quantity} x ${product.code}`
                                                )
                                                .join(", ");
                                        payload.push({
                                            No: i + 1,
                                            Customer:
                                                order.createdBy
                                                    .name,
                                            SKU: productCodes,
                                            Debit: `${
                                                order.amount -
                                                order.discount -
                                                (order.deposit
                                                    ? order.deposit
                                                    : 0)
                                            }`,
                                            Credit: `${
                                                order.credit
                                                    ? order.credit
                                                    : 0
                                            }`,
                                            Date: moment(
                                                order.createdAt
                                            ).format("YYYY-MM-DD"),
                                        });
                                    }
                                );
                                excel({
                                    excelData: payload,
                                    fileName: `SSR-Orders-${moment(
                                        inputs.dateFrom
                                    ).format(
                                        "DD-MM-YYYY"
                                    )}-to-${moment(
                                        inputs.dateTo
                                    ).format("DD-MM-YYYY")}`,
                                });
                            }}
                            className="red buttonTop productListButton"
                        >
                            Export
                        </button>

                        <div className="reportContainer">
                            <div className="featuredReport blue">
                                <span className="featuredTitle">
                                    Total Debit:
                                </span>
                                <div className="featuredReportContainer">
                                    <span className="featuredMoney">
                                        {" "}
                                        RM{" "}
                                        {formatter.format(
                                            ordersData.totalAmount
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="featuredReport red">
                                <span className="featuredTitle">
                                    Total Credit:
                                </span>
                                <div className="featuredReportContainer">
                                    <span className="featuredMoney">
                                        {" "}
                                        RM{" "}
                                        {formatter.format(
                                            ordersData.totalCredit
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="featuredReport green">
                                <span className="featuredTitle">
                                    Total Profit:
                                </span>
                                <div className="featuredReportContainer">
                                    <span className="featuredMoney">
                                        {" "}
                                        RM{" "}
                                        {formatter.format(
                                            ordersData.totalAmount -
                                                ordersData.totalCredit
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
                <>
                                                <div className="mb-16">
                        <div className="text-black font-bold py-4 text-3xl text-center bg-gradient-to-r from-red-800 via-yellow-600 to-yellow-500 capitalize">
                            Trading Report
                        </div>
                        <Table
                            data={[{name: "Turn Over", monthly: turnover, yearly: yearlyturnover}, {name: "Cost of Goods Sold", monthly: cost, yearly: yearlycost}, {name: "Operating Expenses", monthly: expenses, yearly: yearlyexpenses}]}
                            columns={columns}
                            pageCount={
                                ordersData
                                    ? Math.floor(
                                          ordersData.totalPages / 20
                                      ) + 2
                                    : 1
                            }
                            totalCount={ordersData.totalPages}
                            updatePagination={setInputs}
                            paginationState={inputs}
                            hidePagination={true}
                            updateLoaded={setIsLoaded}
                            total={[formatter.format(turnover - cost - expenses), formatter.format(yearlyturnover - yearlycost - yearlyexpenses)]}
                        />
                    </div>

                    <div className="mb-16">
                    <div className="text-gray-900 font-bold py-4 text-3xl text-center bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 capitalize">
                            Turn Over
                        </div>
                        <Table
                            data={ordersData ? ordersData.turnover : []}
                            columns={columns}
                            pageCount={
                                ordersData
                                    ? Math.floor(
                                          ordersData.totalPages / 20
                                      ) + 2
                                    : 1
                            }
                            totalCount={ordersData.totalPages}
                            updatePagination={setInputs}
                            paginationState={inputs}
                            hidePagination={true}
                            updateLoaded={setIsLoaded}
                            total={[formatter.format(turnover), formatter.format(yearlyturnover)]}
                        />
                    </div>

                    <div className="mb-16">
                        <div className="text-gray-900 font-bold py-4 text-3xl text-center bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 capitalize">
                            Cost of Goods Sold
                        </div>
                        <Table
                            data={ordersData ? ordersData.goodssold : []}
                            columns={columns}
                            pageCount={
                                ordersData
                                    ? Math.floor(
                                          ordersData.totalPages / 20
                                      ) + 2
                                    : 1
                            }
                            totalCount={ordersData.totalPages}
                            updatePagination={setInputs}
                            paginationState={inputs}
                            hidePagination={true}
                            updateLoaded={setIsLoaded}

                            total={[formatter.format(cost), formatter.format(yearlycost)]}
                        />
                    </div>

                    <div className="mb-16">
                    <div className="text-gray-900 font-bold py-4 text-3xl text-center bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 capitalize">
                            Operating Expenses
                        </div>
                        <Table
                            data={ordersData ? ordersData.expenses : []}
                            columns={columns}
                            pageCount={
                                ordersData
                                    ? Math.floor(
                                          ordersData.totalPages / 20
                                      ) + 2
                                    : 1
                            }
                            totalCount={ordersData.totalPages}
                            updatePagination={setInputs}
                            paginationState={inputs}
                            hidePagination={true}
                            updateLoaded={setIsLoaded}
                            total={[formatter.format(expenses), formatter.format(yearlyexpenses)]}
                        />
                    </div>
                </>
        </div>
    </>
    );
}
