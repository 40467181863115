import "./orderList.css";
import Table from "../../components/advancedTable";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,

} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getTradeIns, voidCredit, acceptOrder } from "../../redux/apiCalls";
import moment from "moment";

import { publicRequest } from "../../requestMethods";
import createCreditInvoice from "../../components/pdf/createCreditInvoice";
import excel from "../../components/excel/excel";
import { useLocation } from "react-router";

const statusOptions = [
    {
        label: "Deposit",
        value: "deposit",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Void",
        value: "void",
    },
    {
        label: "Warranty",
        value: "warranty",
    },
    {
        label: "All",
        value: "",
    },
];

const limitOptions = [
    {
        label: "Basic",
        value: 20,
    },
    {
        label: "Export",
        value: 9999,
    },
];



export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [inputs, setInputs] = useState({
        createdBy: manager
            ? principle
            : staff
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: undefined,
        limit: 9999,
        page: 1,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        user: location
            ? location.state
                ? location.state.outlet
                : undefined
            : undefined,
            type: "tradein",
    });

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const [statusMenu, setStatusMenu] = useState(false);
    const [ordersData, setOrdersData] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [acceptMenu, setAcceptMenu] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [payloadpaymentType, setPayloadPaymentType] = useState(undefined);
    const [invoice, setInvoice] = useState(undefined);

    // eslint-disable-next-line
const [paymentType, setPaymentType] = useState([
    {
        label: "Cash",
        value: "cash",
    },
    { value: "onlineTransfer", label: "Online Transfer" },
    { value: "tng", label: "Touch N Go" },
    { value: "cheque", label: "Cheque" },
    { value: "card", label: "Debit Card" },
    { value: "creditCard", label: "Credit Card" },
    { value: "ziip6", label: "ZIIP - 6 months" },
    { value: "ziip12", label: "ZIIP - 12 months" },
    { value: "ziip18", label: "ZIIP - 18 months" },
    { value: "ziip24", label: "ZIIP - 24 months" },
    { value: "others", label: "Others" },
]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getTradeIns(dispatch, inputs);
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [dispatch, inputs]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            if (process.env.REACT_APP_COUNTRY === "SINGAPORE") {
                getAdmins();
            } else {
                getAllAdmins();
            }
        }
    }, [usersData]);

    const handlePaymentType = (e) => {
        setPayloadPaymentType(e.value);
    };

    const handleVoid = (id) => {
        voidCredit(id, dispatch);
    };

    const handleAccept = (id) => {
        acceptOrder(id, payloadpaymentType, dispatch);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const handleLimitChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, limit: e.value };
        });
    };

    const handleInvoiceChange = (e) => {
        setInputs((prev) => {
            return { ...prev, invoice: e };
        });
        setIsLoaded(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleInvoiceChange(invoice);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [invoice]);

    const handleDelayedInvoiceChange = (e) => {
        setInvoice(e.target.value);
    };

    const columns = [
        {
            accessor: "action",
            Header: "Action",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon mr-2"
                                onClick={() => {
                                    setModalData(params.row.original._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        <CloudDownload
                            className="pointer icon mr-2"
                            onClick={() => {
                                createCreditInvoice(params.row.original);
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "createdAt",
            Header: "Purchase Date",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            accessor: "product",
            Header: "Products",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon"
                            onClick={() => {
                                setModalData(params.row.original.products);
                                setStatusMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "invoiceId",
            Header: "Invoice",
            sortable: false,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "y",
            Header: "Model",
            sortable: false,
            Cell: (params) => {
                return (
                    <div className="initialPadding">
                        {params &&
                        params.row &&
                        params.row.original &&
                        params.row.original.products &&
                        params.row.original.products[0]
                            ? params.row.original.products[0].code
                            : ""}{" "}
                    </div>
                );
            },
        },
        {
            accessor: "userId",
            Header: "Customer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "products",
            Header: "Total",
            sortable: false,
            Cell: (params) => {
                let total = 0;
                if (params.row.original.products !== undefined) {
                    for (const product of params.row.original.products) {
                        total = total + product.price * product.amount;
                    }
                }
                return (
                    <div className="capitalize">
                        {params
                            ? params.row.original.country === "singapore"
                                ? "$"
                                : "RM"
                            : "RM"}
                        {formatter.format(
                            params.row.original.deposit &&
                                params.row.original.status === "deposit"
                                ? params.row.original.deposit
                                : params.row.original.deposit &&
                                  params.row.original.status !== "deposit"
                                ? total - params.row.original.discount
                                : total - params.row.original.discount
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "paymentType",
            Header: "Payment Type",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "status",
            Header: "Status",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
    ];

    return (
        <>
        <div className="productList">
            <div className="samerow">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="dateFrom"
                        value={inputs.dateFrom}
                        type="date"
                        onChange={(e) => handleDateFrom(e)}
                    />
                </div>
                <div className="addProductItem ">
                    <input
                        className="inputFilter"
                        name="dateTo"
                        value={inputs.dateTo}
                        type="date"
                        onChange={(e) => handleDateTo(e)}
                    />
                </div>
                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleInputStatus}
                        placeholder="Status"
                        onClick={() =>
                            usersData[0] === undefined
                                ? getAdmins()
                                : {}
                        }
                    />
                </div>
                <div className="sameRowItem marginRight">
                    {admin && !staff && (
                        <Select
                            options={limitOptions.map(
                                (status, i) => {
                                    return {
                                        label: `${status.label}`,
                                        value: status.value,
                                    };
                                }
                            )}
                            onChange={handleLimitChange}
                            placeholder={"Format"}
                        />
                    )}
                </div>
            </div>

            <div className="addProductItem ">
            <input
            className="inputFilter"
            name="invoice"
            value={invoice}
            type="string"
            placeholder="Invoice"
            onChange={handleDelayedInvoiceChange}
        />
                </div>

            <div className="block">
                {superadmin && (
                    <button
                        onClick={() => {
                            let payload = [];
                            ordersData.payload.forEach(
                                (order, i) => {
                                    let productCodes =
                                        order.products
                                            .map(
                                                (product) =>
                                                    `${product.quantity} x ${product.code}`
                                            )
                                            .join(", ");
                                    payload.push({
                                        No: i + 1,
                                        Customer:
                                            order.createdBy.name,
                                        SKU: productCodes,
                                        Retail: `${order.amount}`,
                                        Discount: ` ${order.discount}`,
                                        Sales: `${
                                            order.amount -
                                            order.discount -
                                            (order.deposit
                                                ? order.deposit
                                                : 0)
                                        }`,
                                        Deposit: `${
                                            order.deposit
                                                ? order.deposit
                                                : 0
                                        }`,
                                        Outlet: order.userId.name,
                                        Cashier: order.cashier.name,
                                        PurchaseDate: moment(
                                            order.createdAt
                                        ).format("YYYY-MM-DD"),
                                    });
                                }
                            );
                            excel({
                                excelData: payload,
                                fileName: `SSR-Orders-${moment(
                                    inputs.dateFrom
                                ).format("DD-MM-YYYY")}-to-${moment(
                                    inputs.dateTo
                                ).format("DD-MM-YYYY")}`,
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                )}
            </div>
                <Table
                    data={ordersData ? ordersData.payload : []}
                    columns={columns}
                    pageCount={
                        ordersData
                            ? Math.floor(
                                  ordersData.totalPages / 20
                              ) + 2
                            : 1
                    }
                    totalCount={ordersData.totalPages}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                />
            <div className="reportContainer">
                <div className="featuredReport blue">
                    <span className="featuredTitle">
                        Total Orders:
                    </span>
                    <div className="featuredReportContainer">
                        <span className="featuredMoney">
                            {" "}
                            {ordersData.totalPages}
                        </span>
                    </div>
                </div>

                <div className="featuredReport red">
                    <span className="featuredTitle">
                        Total Amount:
                    </span>
                    <div className="featuredReportContainer">
                        <span className="featuredMoney">
                            {" "}
                            RM{" "}
                            {formatter.format(
                                ordersData.totalAmount
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        {statusMenu && (
            <div className="overlayContainer">
                <div className="bigcenter">
                    <div
                        className="close"
                        onClick={() => setStatusMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="updatemodalContainer">
                        <div className="updateOrder">
                            <h1 className="updateProductTitle text-xl font-semibold">
                                Products({modalData.length})
                            </h1>
                            <div className="productContainer">
                                {modalData.map((product) => {
                                    return (
                                        <div
                                            className="product"
                                            key={product}
                                        >
                                            {" "}
                                            <div className="justify-center flex mb-2">
                                                <img
                                                    src={
                                                        product.img
                                                    }
                                                    className="productImg"
                                                    alt="productImages"
                                                />
                                            </div>
                                            <div className="productDetails">
                                                {product.quantity} x{" "}
                                                {product.code}
                                            </div>
                                            <div className="productDetails">
                                                {product.quantity} x
                                                RM
                                                {formatter.format([
                                                    product.price,
                                                ])}
                                            </div>
                                            <div className="productDetails">
                                                RM{" "}
                                                {formatter.format([
                                                    product.quantity *
                                                        product.price,
                                                ])}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {voidMenu && (
            <div className="overlayContainer">
                <div className="center">
                    <div
                        className="close"
                        onClick={() => setVoidMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="actionContainer">
                        <div className="updateOrder">
                            <h3 className="updateProductTitle">
                                Confirm Void Order?
                            </h3>
                            <div className="buttonContainer">
                                <button
                                    className="confirm pointer green"
                                    onClick={() => {
                                        handleVoid(modalData);
                                        setVoidMenu(false);
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="cancel pointer red"
                                    onClick={() => {
                                        setVoidMenu(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {acceptMenu && (
            <div className="overlayContainer">
                <div className="center">
                    <div
                        className="close"
                        onClick={() => setAcceptMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="actionContainer">
                        <div className="updateOrder">
                            <h3 className="updateProductTitle">
                                Confirm Accept Order?
                            </h3>
                            <div className="addProductItem fullWidth">
                                <label>Payment Type</label>
                                <Select
                                    options={paymentType.map(
                                        (paymentType, i) => {
                                            return {
                                                label: paymentType.label,
                                                value: paymentType.value,
                                            };
                                        }
                                    )}
                                    onChange={handlePaymentType}
                                />
                            </div>
                            <div className="buttonContainer">
                                <button
                                    className="confirm pointer green"
                                    onClick={() => {
                                        handleAccept(modalData);
                                        setAcceptMenu(false);
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="cancel pointer red"
                                    onClick={() => {
                                        setAcceptMenu(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
    );
}
